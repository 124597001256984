<template>
  <div>
    <GeneralTableHeader
      @on:search="onSearch"
      @on:sort="onSort"
      actionCreate="/members/create"
    />

    <div class="d-none" id="print-section">
      <img :src="selectedImage" />
      <canvas class="d-none" ref="qrcodeCanvas"></canvas>
    </div>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="state.isLoading"
      hide-default-footer
      no-data-text="No Data"
      class="mt-8"
    >
      <template v-slot:[`item.member_type`]="{ item }">
        <p class="text-capitalize">
          {{ getMemberType(item) }}
        </p>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-card
          flat
          :color="isActive(item) ? 'success' : 'error'"
          :class="['rounded-xl text-center px-2 py-1 white--text']"
        >
          <p class="h8--supersmall">
            {{ isActive(item) ? 'Active' : 'Inactive' }}
          </p>
        </v-card>
      </template>
      <template v-slot:[`item.image`]="{ item }">
        <v-avatar class="my-2">
          <img :src="item.image" alt="avatar" />
        </v-avatar>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-menu auto offset-x rounded="lg">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </span>
          </template>
          <v-list>
            <v-list-item @click="onPrint(item)">
              <v-list-item-title class="d-flex align-center">
                <v-icon size="16" class="mr-3 dark--text"> mdi-printer </v-icon>
                <span class="h8--supersmall dark--text">Print </span>
              </v-list-item-title>
            </v-list-item>

            <v-list-item @click="onDetail(item)">
              <v-list-item-title class="d-flex align-center">
                <v-icon size="16" class="mr-3 primary--text">
                  mdi-pencil
                </v-icon>
                <span class="h8--supersmall primary--text">Edit </span>
              </v-list-item-title>
            </v-list-item>

            <v-list-item @click="handleDialogDelete(item)">
              <v-list-item-title class="d-flex align-center">
                <v-icon size="16" class="mr-3 error--text"> mdi-delete </v-icon>
                <span class="h8--supersmall error--text">Delete</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <GeneralDialogDelete
      @on:dialog="handleDialogDelete"
      @on:delete="onDelete"
      @on:close="handleDialogDelete"
      :loading="state.isLoading"
      :parentDialog="state.dialogDelete"
    />

    <GeneralPagination
      class="mt-8"
      :paging="paging"
      @on:change="onChangePagination"
    />
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import QRCode from 'qrcode'

export default {
  name: 'HomeView',
  data() {
    return {
      headers: [
        {
          text: 'No',
          value: 'no',
          sortable: false,
          class: 'dark--text h7--xxsmall dark lighten-2',
          width: 10
        },
        {
          text: 'Photo',
          value: 'image',
          sortable: false,
          class: 'dark--text h7--xxsmall dark lighten-2'
        },
        {
          text: 'Member ID',
          value: 'member.card_number',
          sortable: false,
          class: 'dark--text h7--xxsmall dark lighten-2'
        },
        {
          text: 'Type',
          value: 'member_type',
          sortable: false,
          class: 'dark--text h7--xxsmall dark lighten-2'
        },
        {
          text: 'Phone',
          value: 'phone',
          sortable: false,
          class: 'dark--text h7--xxsmall dark lighten-2'
        },
        {
          text: 'Name',
          value: 'name',
          sortable: false,
          class: 'dark--text h7--xxsmall dark lighten-2'
        },
        {
          text: 'Valid From',
          value: 'member.valid_from',
          sortable: false,
          class: 'dark--text h7--xxsmall dark lighten-2'
        },
        {
          text: 'Valid To',
          value: 'member.valid_to',
          sortable: false,
          class: 'dark--text h7--xxsmall dark lighten-2'
        },
        {
          text: 'Status',
          value: 'status',
          sortable: false,
          class: 'dark--text h7--xxsmall dark lighten-2'
        },
        {
          text: 'Remaining Days',
          value: 'member.remaining_days',
          sortable: false,
          class: 'dark--text h7--xxsmall dark lighten-2'
        },
        {
          text: 'Action',
          value: 'action',
          sortable: false,
          align: 'center',
          class: 'dark--text h7--xxsmall dark lighten-2',
          width: 50
        }
      ],
      data: null,
      search: '',
      selectedType: 'SEMUA',
      params: {
        page: 1,
        per_page: 10,
        keyword: '',
        sort: 'newest',
        role: 'member'
      },
      paging: {},
      state: {
        isLoading: false,
        selectedItem: {},
        dialogDelete: false
      },
      items: [],
      itemsType: ['SEMUA', 'Active', 'Inactive'],
      selectedImage: null
    }
  },

  created() {
    this.fetch()
  },

  mounted() {
    this.setMeta('Member')
  },

  methods: {
    getMemberType(val) {
      return val.member?.member_type
    },
    isActive(val) {
      return val.member?.status === 'active'
    },
    async fetch() {
      try {
        this.state.isLoading = true
        const res = await this.$api.user.getList(this.params)

        this.items = res.data.list?.map((x, index) => ({
          no: (this.params.page - 1) * this.params.per_page + index + 1,
          ...x
        }))
        this.paging = res.data.paging
      } catch (error) {
        this.setFailedAlert(error)
      }

      this.state.isLoading = false
    },

    onSearch: debounce(function (val) {
      this.params.keyword = val
      this.params.page = 1
      this.fetch()
    }, 500),

    onChangePagination(val) {
      this.params.page = val
      this.fetch()
    },

    onSort(val) {
      this.params.sort = val
      this.params.page = 1
      this.fetch()
    },

    onDetail(val) {
      this.$router.push(`/members/${val.id}`)
    },

    handleDialogDelete(val) {
      if (val?.id) {
        this.state.selectedItem = val
      }
      this.state.dialogDelete = !this.state.dialogDelete
    },

    onPrint(val) {
      const canvas = this.$refs.qrcodeCanvas
      QRCode.toCanvas(
        canvas,
        val['member']['id'],
        {
          width: 500 // Adjust the size of the QR code
        },
        function (error) {
          if (error) console.error(error)
          console.log('QR code generated!')
        }
      )

      const imgDataUrl = canvas.toDataURL('image/png') // Convert canvas to image
      const name = val.name
      this.selectedImage = imgDataUrl

      setTimeout(() => {
        const originalContents = document.body.innerHTML

        document.body.innerHTML = `
              <html>
                <head>
                  <style>
                    @media print {
                      body, html {
                        height: 100%;
                        margin: 0;
                        padding: 0;
                      }
                      #print-section {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                      }
                      #printable-image {
                        max-width: 100%;
                        max-height: 100%;
                      }
                    }
                  </style>
                </head>
                <body>
                   <div style="display:flex;flex-direction:column" id="print-section">
                    <h2>${name}</h2>
                  <img src="${imgDataUrl}" id="printable-image" />
            </div>
                </body>
              </html>
            `

        window.print()

        // // Revert the body back to its original content after printing
        document.body.innerHTML = originalContents
        window.location.reload() // Reload to restore Vue state
      }, 100)
    },

    async onDelete() {
      try {
        this.state.isLoading = true
        await this.$api.user.delete(this.state.selectedItem?.id)
        this.setSuccessAlert('Berhasil menghapus member')
        this.fetch()
        this.state.dialogDelete = false
      } catch (error) {
        this.setFailedAlert(error)
      }
    }
  }
}
</script>
