<template>
  <div>
    <GeneralTableHeader
      class="mb-6"
      @on:search="onSearch"
      @on:sort="onSort"
      actionCreate="/products/create"
    />

    <template v-if="state.isLoading">
      <GeneralLoading />
    </template>

    <template v-if="!state.isLoading">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="3"
          xl="2"
          v-for="(item, index) in items"
          :key="index"
        >
          <div class="pointer" @click="handleDialogDelete(item)">
            <ProductCard :item="item" />
          </div>
        </v-col>
      </v-row>

      <ProductDialog
        @on:dialog="handleDialogDelete"
        @on:delete="onDelete"
        @on:close="handleDialogDelete"
        @on:refresh="fetch"
        :loading="state.isLoading"
        :parentDialog="state.dialogDelete"
        :item="state.selectedItem"
      />

      <GeneralPagination
        class="mt-8"
        :paging="paging"
        @on:change="onChangePagination"
      />
    </template>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  name: 'HomeView',
  data() {
    return {
      headers: [
        {
          text: 'No',
          value: 'no',
          sortable: false,
          class: 'dark--text h7--xxsmall dark lighten-2',
          width: 10
        },
        {
          text: 'Image',
          value: 'image',
          sortable: false,
          class: 'dark--text h7--xxsmall dark lighten-2'
        },
        {
          text: 'Name',
          value: 'name',
          sortable: false,
          class: 'dark--text h7--xxsmall dark lighten-2'
        },
        {
          text: 'Description',
          value: 'description',
          sortable: false,
          class: 'dark--text h7--xxsmall dark lighten-2'
        },
        {
          text: 'Price',
          value: 'price',
          sortable: false,
          class: 'dark--text h7--xxsmall dark lighten-2'
        },
        {
          text: 'Stock',
          value: 'stock',
          sortable: false,
          class: 'dark--text h7--xxsmall dark lighten-2'
        },

        {
          text: 'Action',
          value: 'action',
          sortable: false,
          align: 'center',
          class: 'dark--text h7--xxsmall dark lighten-2',
          width: 50
        }
      ],
      data: null,
      search: '',
      selectedType: 'SEMUA',
      params: {
        page: 1,
        per_page: 12,
        keyword: '',
        sort: 'created_at'
      },
      paging: {},
      state: {
        isLoading: false,
        selectedItem: {},
        dialogDelete: false
      },
      items: [],
      itemsType: ['SEMUA', 'Active', 'Inactive']
    }
  },

  created() {
    this.fetch()
  },

  mounted() {
    const appBar = {
      title: 'Products',
      child: false
    }

    this.$store.commit('SET_META', appBar)
  },

  methods: {
    async fetch() {
      this.state.isLoading = true
      const res = await this.$api.product.getList(this.params)

      if (res.success) {
        this.items = res.data.list?.map((x, index) => ({
          no: (this.params.page - 1) * this.params.per_page + index + 1,
          ...x
        }))
        this.paging = res.data.paging
      }

      if (!res.success) {
        this.setFailedAlert(res.error)
      }

      this.state.isLoading = false
    },

    onSearch: debounce(function (val) {
      this.params.keyword = val
      this.params.page = 1
      this.fetch()
    }, 500),

    onChangePagination(val) {
      this.params.page = val
      this.fetch()
    },

    onSort(val) {
      this.params.sort = val
      this.params.page = 1
      this.fetch()
    },

    onDetail(val) {
      this.$router.push(`/products/${val.id}`)
    },

    handleDialogDelete(val) {
      if (val?.id) {
        this.state.selectedItem = val
      }
      this.state.dialogDelete = !this.state.dialogDelete
    },

    async onDelete() {
      try {
        this.state.isLoading = true
        this.$api.product.delete(this.state.selectedItem?.id)
        this.setSuccessAlert('Data berhasil dihapus')
        this.fetch()
      } catch (error) {
        this.setFailedAlert(error)
      }
      this.state.dialogDelete = false
    },

    onErrorImage(event) {
      event.target.src = '/static/empty-img.jpg'
    }
  }
}
</script>
