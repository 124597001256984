<template>
  <div class="member-card">
    <div class="member-card_number mt-8">
      <p class="text--small">Member ID</p>
      <p>{{ profile.member.card_number }}</p>
    </div>
    <div class="member-card_info mt-2">
      <div class="p--small">
        <p>Berlaku Sampai</p>
        <p>{{ profile.member.valid_to }}</p>
      </div>

      <p class="h6--xsmall">{{ profile.name }}</p>
    </div>
    <div class="member-card_logo">
      <v-img width="80" src="/static/bugar-gym-logo-white.png" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    profile: Object
  }
}
</script>
<style lang="scss" scoped>
.member-card {
  background: linear-gradient(135deg, #2884da, #c7a3df);
  width: 'auto';
  height: 200px;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 8px 16px rgba(77, 73, 73, 0.3);
  color: white;
  position: relative;
  overflow: hidden;

  &_number {
    font-size: 22px;
    letter-spacing: 2px;
    margin-bottom: 15px;
  }

  &_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &_logo {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 18px;
    font-weight: bold;
  }
}
</style>
