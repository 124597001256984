<template>
  <div
    class="login-page container--fluid d-flex flex-column justify-center align-center"
  >
    <div class="login-page_form">
      <v-img
        class="d-flex mx-auto mb-10"
        width="120"
        src="/static/bugar-gym-logo-white.png"
      ></v-img>
      <div class="login-page_form-input">
        <FormTextField
          v-model="form.phone"
          outlined
          label="Phone"
          rounded
          hide-details="auto"
          dense
          className="secondary--text"
          :labelWhite="true"
          dark
        />
        <FormTextField
          v-model="form.password"
          dense
          className="secondary--text"
          dark
          class="mt-4"
          outlined
          label="Password"
          rounded
          type="password"
          hide-details="auto"
          @keypress.enter="login()"
        />
      </div>

      <div
        class="p--small text-right text-decoration-underline mt-4 error--text"
      >
        <span>Lupa Password</span>
      </div>

      <v-btn
        block
        depressed
        large
        rounded
        class="mt-6 mb-4 secondary h7--xxsmall"
        :loading="state.isLoading"
        @click="login()"
        >Masuk</v-btn
      >

      <div class="p--small">
        <span
          >Belum Punya akun?
          <span class="text-decoration-underline secondary--text">
            Register disini
          </span></span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    form: {
      phone: '',
      password: ''
    },
    state: {
      isLoading: false
    }
  }),

  methods: {
    async login() {
      this.state.isLoading = true
      // console.log(this.$store.dispatch('auth'))

      const form = {
        phone: this.form.phone,
        password: this.form.password
      }

      await this.$store.dispatch('auth/login', form)

      this.state.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.login-page {
  background: url('../../assets/login-bg.png');
  background-size: cover;
  height: 100%;

  &_form {
    @media (min-width: 600px) {
      width: 350px;
    }
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.37);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    color: white;
    text-align: center;
    transition: transform 0.5s ease, background 0.5s ease,
      border-radius 0.5s ease;
    padding: 24px;
    margin: 24px;
  }
}
</style>
