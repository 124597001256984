<template>
  <div>
    <v-card class="d-inline-block mb-6 success pa-6 shadow-base">
      <p class="h6--xsmall white--text mb-4">Online Activity</p>
      <h3 class="h3--large white--text">
        {{ activeUser }}
      </h3>
    </v-card>

    <h4 class="h4--default mb-4">Sales Report</h4>
    <SalesChart />
  </div>
</template>

<script>
import SalesChart from '@/components/dashboard.vue/SalesChart.vue'

export default {
  components: {
    SalesChart: SalesChart
  },

  data: () => ({
    activeUser: 0
  }),

  created() {
    this.getActivity()
  },

  mounted() {
    this.setMeta('Dashboard', false)
  },

  methods: {
    async getActivity() {
      const res = await this.$api.dashboard.getOnlineActivity()

      this.activeUser = res.check_ins_today
      console.log(res)
    }
  }
}
</script>
