<template>
  <div class="scanner-container rounded-lg">
    <div class="scanner-frame">
      <div class="corner top-left"></div>
      <div class="corner top-right"></div>
      <div class="corner bottom-left"></div>
      <div class="corner bottom-right"></div>
      <qrcode-stream
        v-if="!dialog"
        class="scanner"
        @decode="onDecode"
        @init="onInit"
      ></qrcode-stream>
    </div>
    <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>

    <p class="h7--xxsmall mt-8">Scan the QR Code Member</p>

    <!-- <v-btn
      depressed
      class="secondary h7--xxsmall mt-4"
      @click="triggerAbsenceAPI('a75a18ce-9d54-4f5a-b04e-3d6b86c554ec')"
      >Scan
    </v-btn> -->

    <v-dialog v-model="dialog" fullscreen v-if="dialog">
      <div
        class="white pa-6 fill-height d-flex flex-column justify-center align-center"
      >
        <v-card flat max-width="200">
          <SuccessLottie />
        </v-card>

        <p class="dark--text h4--default">
          {{ res.status === 'check_in' ? 'Check In' : 'Check Out' }} Berhasil
        </p>
        <v-btn
          @click="onBack()"
          class="secondary mt-4 text-capitalize h7--xxsmall"
          >Kembali</v-btn
        >
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'
import alert from '@/mixins/alert'
export default {
  mixins: [alert],
  components: {
    QrcodeStream
  },
  data() {
    return {
      dialog: false,
      errorMessage: '',
      res: {}
    }
  },

  mounted() {
    this.setMeta('Scan', false)
  },

  methods: {
    onInit(promise) {
      promise
        .then(() => {
          console.log('Camera successfully initialized.')
        })
        .catch((error) => {
          this.errorMessage = 'Unable to access camera: ' + error.message
        })
    },
    onDecode(decodedString) {
      this.triggerAbsenceAPI(decodedString)
    },
    async triggerAbsenceAPI(memberId) {
      const payload = {
        member_id: memberId
      }
      try {
        const res = await this.$api.absence.post(payload)
        const success = this.isSuccess(res.success)

        if (success) {
          this.dialog = !this.dialog
          // this.setSuccessAlert('Berhasil')
          this.res = res.data
        }
      } catch (error) {
        console.log('error catch', error)
        this.setFailedAlert({ ...error.response?.data })
        this.errorMessage =
          'Failed to check in: ' + error.response?.data?.error?.message
      }
    },
    onBack() {
      this.dialog = false
      // this.$router.replace('/scan')
    }
  }
}
</script>

<style scoped>
.scanner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
}

.scanner-frame {
  position: relative;
  width: 300px; /* Set the desired width of the scanner */
  height: 300px; /* Set the desired height of the scanner */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  overflow: hidden;
  --s: 50px; /* the size on the corner */

  padding: 20px; /* the gap between the border and image */
  border: 5px solid var(--v-secondary-base); /* the thickness and color */
  -webkit-mask: conic-gradient(at var(--s) var(--s), #0000 75%, #000 0) 0 0 /
      calc(100% - var(--s)) calc(100% - var(--s)),
    linear-gradient(#000 0 0) content-box;
}

.corner {
  position: absolute;
  width: 30px;
  height: 30px;
  border: 5px solid #ccc; /* Color of the frame corner */
  border-radius: 10px;
}

.top-left {
  top: -5px;
  left: -5px;
  border-right: none;
  border-bottom: none;
}

.top-right {
  top: -5px;
  right: -5px;
  border-left: none;
  border-bottom: none;
}

.bottom-left {
  bottom: -5px;
  left: -5px;
  border-right: none;
  border-top: none;
}

.bottom-right {
  bottom: -5px;
  right: -5px;
  border-left: none;
  border-top: none;
}

.scanner {
  width: 100%;
  height: 100%;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}
</style>
