<template>
  <div>
    <v-card class="shadow-base pa-6 rounded-lg mx-auto" max-width="600">
      <v-form v-model="state.isValid" ref="form">
        <div class="mb-6">
          <p class="h6--xsmall">Photo</p>
          <template v-if="imagePreview">
            <div class="d-flex align-start">
              <img
                width="250"
                height="250"
                class="rounded-xl cover mt-2 image-preview"
                :src="imagePreview"
                alt="img"
              />
              <v-btn icon @click="onRemoveItem()"
                ><v-icon color="error">mdi-close-circle</v-icon></v-btn
              >
            </div>
          </template>
          <!-- Product Image Upload -->
          <template v-if="!form.image">
            <v-file-input
              v-model="form.image"
              class="mt-6"
              label="Upload Image"
              prepend-icon="mdi-camera"
              accept="image/*"
              outlined
              dense
              hide-details="auto"
              @change="onFilePicked"
            />
          </template>
        </div>

        <FormSelect
          v-model="form.role"
          :items="items.roles"
          item-text="name"
          item-value="key"
          class="my-6"
          label="Role"
          hide-details="auto"
          bold
          dense
          outlined
          :rules="[required]"
        />

        <FormTextField
          v-model="form.name"
          label="Name"
          hide-details="auto"
          bold
          dense
          outlined
          :rules="[required]"
        />

        <FormTextField
          :maxLength="16"
          v-model="form.phone"
          class="mt-6"
          label="Phone Number"
          hide-details="auto"
          bold
          dense
          outlined
          :rules="[required, number]"
          @keypress="onDigits"
        />

        <FormSelect
          v-model="form.gender"
          :items="items.gender"
          item-text="name"
          item-value="key"
          class="mt-6"
          label="Gender"
          hide-details="auto"
          bold
          dense
          outlined
          :rules="[required]"
        />

        <FormDatePicker
          v-model="form.birthday"
          :dateValue="form.birthday"
          :max="minDate"
          label="Birthday (Bulan/Tanggal/Tahun)"
          formatDateText="DD MMM YYYY"
          bold
          :rules="[required]"
          class="my-6"
        />

        <FormTextArea
          v-model="form.address"
          class="mt-6"
          label="Address"
          hide-details="auto"
          bold
          dense
          outlined
          :rules="[required]"
        />

        <template v-if="isCreated">
          <FormTextField
            v-model="form.password"
            class="mt-6"
            label="Password"
            hide-details="auto"
            bold
            dense
            outlined
            type="password"
            :rules="[required]"
          />
        </template>

        <div class="d-flex justify-end mt-8">
          <v-btn
            depressed
            class="mr-4 h7--xxsmall text-capitalize"
            :loading="state.isLoading"
            @click="onBack()"
            >Back</v-btn
          >
          <v-btn
            depressed
            color="h7--xxsmall text-capitalize secondary"
            :loading="state.isLoading"
            @click="onSave()"
          >
            Save
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { GENDER, ROLES } from '@/data/general'
import rules from '@/mixins/rules'
import alert from '@/mixins/alert'
import utils from '@/mixins/utils'

export default {
  mixins: [rules, alert, utils],
  data() {
    return {
      id: '',
      form: {
        name: '',
        gender: '',
        address: '',
        birthday: '',
        role: 'admin',
        password: '',
        phone: '',
        image: null
      },
      imagePreview: '',
      state: {
        isLoading: false,
        isValid: true
      },
      items: {
        gender: GENDER,
        roles: ROLES
      }
    }
  },

  created() {
    this.id = this.$route.params.slug

    if (!this.isCreated) {
      this.getOne()
    }
  },

  computed: {
    isCreated() {
      return this.id === 'create'
    }
  },

  mounted() {
    const appBar = {
      title: 'Users',
      child: true
    }
    this.$store.commit('SET_META', appBar)
  },

  methods: {
    async getOne() {
      const res = await this.$api.user.getOne(this.id)
      this.form = res.data
      this.imagePreview = this.form.image

      if (!res.success) {
        this.setFailedAlert(res)
      }
    },

    async onSave() {
      this.state.isLoading = true
      const valid = await this.validate(this.state.isValid)

      if (valid) {
        const formData = new FormData()
        formData.append('name', this.form.name)
        formData.append('birthday', this.form.birthday)
        formData.append('gender', this.form.gender)
        formData.append('address', this.form.address)
        formData.append('phone', this.form.phone)
        formData.append('role', this.form.role)

        if (this.isCreated) {
          formData.append('password', this.form.password)
        }
        if (this.form.image) {
          formData.append('image', this.form.image)
        }

        const res = this.isCreated
          ? await this.$api.user.post(formData)
          : await this.$api.user.put(this.id, formData)

        if (res.success) {
          this.setSuccessAlert('Berhasil Menyimpan Data')
          this.$router.push('/users')
        }

        if (!res.success) {
          this.setFailedAlert(res)
        }
      }

      this.state.isLoading = false
    },

    onBack() {
      this.$router.push('/users')
    },

    onFilePicked(file) {
      if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.imagePreview = e.target.result // Set the data URL for the image preview
        }
        reader.readAsDataURL(file) // Read the file as a data URL
      }
    },

    onRemoveItem() {
      this.form.image = null
      this.imagePreview = ''
    }
  }
}
</script>
