<template>
  <div>
    <p
      class="text-capitalize mb-2 dark--text"
      v-if="label"
      v-bind:class="{ 'h6--xsmall': bold, 'text--default': !bold }"
    >
      {{ label }}
    </p>

    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          dense
          v-model="dateText"
          :rules="rules"
          outlined
          append-icon="mdi-calendar"
          readonly
          :placeholder="placeholder"
          v-bind="attrs"
          v-on="on"
          hide-details="auto"
        />
      </template>
      <v-date-picker
        v-bind="$attrs"
        v-on="$listeners"
        color="#1B70A8"
        @input="onInputDate"
      />
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    dateValue: String,
    rules: Array,
    bold: {
      type: Boolean,
      default: () => false
    },
    label: {
      type: String,
      default: () => ''
    },
    placeholder: {
      type: String,
      default: () => 'Pilih Tanggal'
    },
    formatDateText: String
  },

  data: () => ({
    menu: false,
    date: ''
  }),

  computed: {
    dateText() {
      let text = this.formatDateText ? this.formatDateText : 'ddd, DD MMM YYYY'
      return this.date ? this.$dayjs(this.date).format(text) : ''
    }
  },

  methods: {
    onInputDate(val) {
      this.date = val
      this.menu = false
    }
  },

  watch: {
    dateValue: {
      handler(val) {
        if (val) {
          this.onInputDate(val)
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.v-input--is-disabled {
  background-color: var(--v-gray-lighten3) !important;
}
</style>
