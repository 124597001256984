import Vue from 'vue'
import App from './App.vue'
import router from './router'
import dayjs from 'dayjs'

// ALERT
import alert from '@/mixins/alert'
import meta from '@/mixins/meta'

// PLUGINS
import api from '@/plugins/api'
import vuetify from '@/plugins/vuetify'
import axios from '@/plugins/axios'
import quill from '@/plugins/nuxt-quill-plugin'

// COMPONENT GENERAL
import GeneralLoading from './components/GeneralLoading.vue'
import GeneralPagination from './components/GeneralPagination.vue'
import GeneralTableHeader from './components/GeneralTableHeader.vue'
import GeneralAppBar from './components/GeneralAppBar.vue'
import GeneralMobileAppBar from './components/GeneralMobileAppBar.vue'
import GeneralSnackBar from './components/GeneralSnackBar.vue'
import GeneralDialogDelete from './components/dialog/GeneralDelete.vue'
import GeneralDateRange from './components/GeneralDateRange.vue'

import ProductDialog from './components/dialog/ProductDialog.vue'
// COMPONENT FORM
import FormTextArea from './components/form/TextArea.vue'
import FormTextField from './components/form/TextField.vue'
import FormSelect from './components/form/TextSelect.vue'
import FormRichEditor from './components/form/RichEditor.vue'
import FormDatePicker from './components/form/DatePicker.vue'

// IRREGULAR COMPONENT
import FormAutoComplete from './components/FormAutoComplete.vue'
import DailyConstructionCard from './components/DailyConstructionCard.vue'
import NewsCard from './components/card/NewsCard.vue'
import ProductCard from './components/card/ProductCard.vue'
import VueApexCharts from 'vue-apexcharts'
import MemberCard from './components/card/MemberCard.vue'

// LOTTIES
import SuccessLottie from './components/lotties/SuccessLottie.vue'

import store from './store'

Vue.config.productionTip = false
Vue.prototype.$dayjs = dayjs

Vue.component('GeneralLoading', GeneralLoading)
Vue.component('GeneralPagination', GeneralPagination)
Vue.component('GeneralTableHeader', GeneralTableHeader)
Vue.component('GeneralAppBar', GeneralAppBar)
Vue.component('GeneralMobileAppBar', GeneralMobileAppBar)
Vue.component('GeneralSnackBar', GeneralSnackBar)
Vue.component('GeneralDialogDelete', GeneralDialogDelete)
Vue.component('GeneralDateRange', GeneralDateRange)
Vue.component('ProductDialog', ProductDialog)

Vue.component('FormTextField', FormTextField)
Vue.component('FormTextArea', FormTextArea)
Vue.component('FormSelect', FormSelect)
Vue.component('FormRichEditor', FormRichEditor)
Vue.component('FormDatePicker', FormDatePicker)

Vue.component('FormAutoComplete', FormAutoComplete)
Vue.component('DailyConstructionCard', DailyConstructionCard)
Vue.component('NewsCard', NewsCard)
Vue.component('MemberCard', MemberCard)
Vue.component('ProductCard', ProductCard)
Vue.component('SuccessLottie', SuccessLottie)
Vue.component('ApexChart', VueApexCharts)

Vue.mixin(alert)
Vue.mixin(meta)

Vue.use(axios, {
  redirect: (url) => router.push(url) // Pass the redirect function
}) //
Vue.use(api)
Vue.use(quill)
store.$api = Vue.prototype.$api

store.dispatch('auth/fetchProfile')

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App)
}).$mount('#app')
